import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import Dropdowns from "@/store/modules/dropdownModule";
import JsonExcel from "vue-json-excel";
import { mdiTableSearch, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline, mdiCalendarExport } from "@mdi/js";
import { Efectivo, TipoDenominacion, Kiosko } from "apd.models";
import { TableHeader } from "@/models/vuetifyModels";
import {
  ApiSistemaPagos,
  ApiGobiernoChihuahua,
  ApiErrorResponse,
  AxiosHttpClient,
  getUrl,
  ApiList,
} from "apd.apiconnectors";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);
@Component({
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
})
export default class ReporteToneleroComponent extends Vue {
  public IconTSearch: string = mdiTableSearch;
  public IconCloseCh: string = mdiCloseBox;
  public IconMinusCh: string = mdiMinusBox;
  public IconCheckbox: string = mdiCheckboxBlankOutline;
  public IconExport: string = mdiCalendarExport;

  public formCompleted = false;
  public mostrarActasDisp = false;
  public dialog = false;
  public resumen = false;
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public clienteGob = (): ApiGobiernoChihuahua => {
    const client = new AxiosHttpClient(getUrl(ApiList.GobiernoChihuahua, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiGobiernoChihuahua(client);
  };

  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];
  public json_fields = {
    ModoPago: "modoPago",
    EstatusPago: "estatusPago",
    TipoDenominacion: "tipoDenominacion",
    Denominacion: "denominacion",
    Cantidad: "cantidad",
  };

  public headers: TableHeader[] = [
    {
      text: "Modo Pago",
      value: "modoPago",
      align: "center",
      sortable: false,
    },
    {
      text: "Estatus Pago",
      value: "estatusPago",
      align: "center",
      sortable: false,
    },
    {
      text: "Tipo Denominacion",
      value: "tipoDenominacion",
      align: "center",
      sortable: false,
    },
    {
      text: "Denominacion",
      value: "denominacion",
      align: "center",
      sortable: false,
    },
    {
      text: "Cantidad",
      value: "cantidad",
      align: "center",
      sortable: false,
    },
  ];

  public detalle = false;
  public actasDisponibles = 0;
  public cajero = "";
  public formDataED: Efectivo[] = [];
  public formDataIngresos: Efectivo[] = [];
  public formDataEgresos: Efectivo[] = [];
  public formDataNoDispensados: Efectivo[] = [];
  public cajerosArr = [] as Kiosko[];

  public mounted(): void {
    this.cajeros();
  }

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public get reporteNombre(): string {
    return "ReporteToneleros.xls";
  }

  public cajeros(): void {
    Spinner.Show();
    Dropdowns.GetKioskos()
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(() => {
        this.cajerosArr = Dropdowns.ListaKioskos() as Kiosko[];
        const user = this.$store.getters["oidcStore/oidcUser"];
        if (user["user_type"] != "Empleado") {
          this.cajerosArr = this.cajerosArr.filter((c) => c.idEmpresa == user["RSP.Empresa"]);
        } else {
          this.cajerosArr = this.cajerosArr.filter(
            (o) =>
              o.mostrarReportes ||
              (o.ubicacion == "WEB" && !o.nombreReportes.startsWith("Caj") && !o.nombreReportes.endsWith("Ext")),
          );
        }
        this.cajerosArr.sort((a, b) => a.nombreReportes.localeCompare(b.nombreReportes));
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        Snackbar.Show(err.apiError.mensajeUsuario);
      });
    Spinner.Hide();
  }

  public tipoDenominacion(id: number): unknown {
    return TipoDenominacion[id];
  }

  public async detalleTonelero(): Promise<void> {
    Spinner.Show();
    try {
      this.clienteSP()
        .ObtenerIngresosKiosko(this.cajero)
        .then((resp) => {
          this.formDataIngresos = (resp as Efectivo[]).sort((a, b) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return a.denominacion! - b.denominacion!;
          });
        });
      this.clienteSP()
        .ObtenerEgresosKiosko(this.cajero)
        .then((resp) => {
          this.formDataEgresos = (resp as Efectivo[]).sort((a, b) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return a.denominacion! - b.denominacion!;
          });
        });

      this.detalle = true;
    } catch (e) {
      const err: ApiErrorResponse = e as ApiErrorResponse;
      this.detalle = false;
      Snackbar.Show(err.apiError.mensajeUsuario);
    } finally {
      Spinner.Hide();
    }
  }

  public buscar(): void {
    Spinner.Show();
    this.obtenerActasDisponibles();
    this.clienteSP()
      .ConsultarEfectivoDisponible(this.cajero)
      .then((resp) => {
        this.formDataED = resp as Efectivo[];
        this.formDataED.sort((a, b) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return a.denominacion! - b.denominacion!;
        });
        this.resumen = true;
        Spinner.Hide();
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        this.formDataED = [];
        this.resumen = false;
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }

  public obtenerActasDisponibles(): void {
    this.clienteGob()
      .ConsultarActasDisponibles(this.cajero)
      .then((resp) => {
        this.actasDisponibles = resp as number;
        this.mostrarActasDisp = true;
      })
      .catch(() => {
        this.mostrarActasDisp = false;
        this.actasDisponibles = 0;
      });
  }
}
